export const subdomain = window.location.host.split('.')[1]
  ? window.location.host.split('.')[0]
  : false;

export const salesPeeps = [
  {
    domain: 'yonahweiss',
    name: 'Yonah Weiss',
    image:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/big_1621940209-avatar-yonahw18_uwbvdz.jpg',
    calendly: 'https://calendly.com/yonahweiss/cost-segregation-call?month=2021-11',
    formLink: '/feasibility-analysis',
    linkedIn: 'https://www.linkedin.com/in/cost-segregation-yonah-weiss/',
    facebook: 'https://www.facebook.com/yonah.weiss.1',
    bigPockets: 'https://www.biggerpockets.com/users/yonahw18',
    itunes: 'https://podcasts.apple.com/us/podcast/weiss-advice/id1515387561',
    message: 'Hey! Yonah Weiss here, your cost-seg advisor. What would you like to know?',
  },
  {
    domain: 'brianbigham',
    name: 'Brian Bigham',
    // image: 'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/brianbigham_yssebj.jpg',
    image:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/DSC_8749HighRes_ezzcpk.jpg',
    calendly: 'https://calendly.com/costsegcowboy',
    linkedIn: 'https://www.linkedin.com/in/brian-bigham-915a1149/',
    facebook: 'https://www.facebook.com/brian.bigham.9693',
    nickname: 'Cost Seg Cowboy',
    message: 'Hey! Cost Seg Cowboy here, your cost-seg advisor. What would you like to know?',
  },
  {
    domain: 'isaacweinberger',
    name: 'Isaac Weinberger',
    linkedIn: 'https://www.linkedin.com/in/isaac-weinberger-b0b749225/',
    image:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/about/Isaac_Weinberger_suhixr.jpg',
    calendly: 'https://calendly.com/specs-with-isaac-weinberger/15min',
    email: 'iweinberger@madisonspecs.com',
    nickname: '#costsegisaac',
    youtube: 'https://www.youtube.com/@StageDebateCSI',
    message: 'Hey! #costsegisaac here. What would you like to know?',
  },
  {
    domain: 'edenmarkowitz',
    name: 'Eden Markowitz',
    image:
      'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/about/eden_markowitz_bik9d4.jpg',
    linkedIn: 'https://www.linkedin.com/in/eden-markowitz-11869982/',
    calendly: 'https://calendly.com/edenmarkowitz/30min',
    email: 'emarkowitz@madisonspecs.com',
    message: 'Hey! Eden Markowitz here, your cost-seg advisor. What would you like to know?',
  },
];

export function nameFinder() {
  return salesPeeps.some((person) => person.domain === subdomain);
}

/* map over the salesPeeps array and return the person object that matches the subdomain */
export function findPerson() {
  return salesPeeps.find((person) => person.domain === subdomain);
}
