/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState } from 'react';

import { Facebook, LinkedIn, Arrow, XCircle } from './svg/salesPerson';
import {
  Root,
  LargeCard,
  DesktopCard,
  Button,
  Collapser,
  NavLinkButton,
  EmailIcon,
} from './salesPersonStyles';
import NoOverflow, { BackgroundOverlay } from './NoOverflow';
import { subdomain } from './salePeeps';
import YoutubeIcon from './svg/youtube.svg';

const itunesImage =
  'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/itunes_podcast_logo_j7vx8r.jpg';

const bigpocketImg =
  'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/specs/bigger-pockets_dcynzm.png';

// Same Comment below:
// If and when adding another sales person subdomain
// IT will need to be reached out to set up newsubdomain
// and then in Netlify, the new subdomain will need to be
// added to the list of custom subdomains.
// Thank you

const determinePath = (subdomain) => {
  if (subdomain === 'yonahweiss') {
    return 'yonah';
  } else if (subdomain === 'isaacweinberger') {
    return 'isaac';
  } else if (subdomain === 'brianbigham') {
    return 'brian';
  } else {
    return null;
  }
};

export default function MobileSalesPersonCard({ noShow, person }) {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <>
      {isExpanded ? (
        <BackgroundOverlay>
          <NoOverflow />
          <SalesPersonCard close={() => setExpanded(false)} person={person} />
        </BackgroundOverlay>
      ) : (
        <>
          <Root onClick={() => setExpanded(!isExpanded)} noShow={noShow}>
            <img className='profile-img' src={person.image} alt='' />
            <div className='name'>{person.name}</div>
            <p className='title'>Cost Segregation Expert</p>
            <div className='help'>
              <div>How can i help? </div>
              <Arrow />
            </div>
          </Root>
          <SalesPersonCardDT noShow={noShow} person={person} />
        </>
      )}
    </>
  );
}

function SalesPersonCard({ close, hide, person }) {
  return (
    <LargeCard hide={hide}>
      <XCircle className='x-close' onClick={close} />
      <img className='profile-img' src={person.image} alt='' />
      <div className='name'>{person.name}</div>
      <p className='title'>Cost Segregation Expert</p>
      <div className='icons'>
        {person.linkedIn && (
          <a href={person.linkedIn} target='_blank' rel='noopener noreferrer'>
            <LinkedIn />
          </a>
        )}
        {person.facebook && (
          <a href={person.facebook} target='_blank' rel='noopener noreferrer'>
            <Facebook />
          </a>
        )}
        {person.bigPockets && (
          <a href={person.bigPockets} target='_blank' rel='noopener noreferrer'>
            <img src={bigpocketImg} alt='big pockets' />
          </a>
        )}
        {person.itunes && (
          <a href={person.itunes} target='_blank' rel='noopener noreferrer'>
            <img src={itunesImage} alt='itunes' />
          </a>
        )}
        {person.youtube && (
          <a href={person.youtube} target='_blank' rel='noopener noreferrer'>
            <img src={YoutubeIcon} alt='youtube icon' />
          </a>
        )}
        {person.email && (
          <a
            href={`mailto: ${person.email}`}
            target='_blank'
            rel='noopener noreferrer'
            className='customSocialCircle'
          >
            <EmailIcon />
          </a>
        )}
      </div>
      <div className='help'>
        <div>How can i help?</div>
        <div>{person.message}</div>
      </div>
      <NavLinkButton to={subdomain === 'brianbigham' || subdomain === 'edenmarkowitz' ? '/custom-quote' : `/feasibility-analysis/${determinePath(subdomain)}`} target='_blank'>
        How can I get a<br />
        free feasibility analysis?
      </NavLinkButton>
      <NavLinkButton to='/faq'>
        Where can I learn more
        <br />
        about Cost-seg?
      </NavLinkButton>
      {person.calendly && (
        <Button target='_blank' href={person.calendly}>
          When can we talk?
        </Button>
      )}
    </LargeCard>
  );
}

// Same Comment Above:
// If and when adding another sales person subdomain
// IT will need to be reached out to set up newsubdomain
// and then in Netlify, the new subdomain will need to be
// added to the list of custom subdomains.
// Thank you

function SalesPersonCardDT({ noShow, person }) {
  const [expanded, setExpanded] = useState(false);

  return (
    <DesktopCard noShow={noShow} expanded={expanded} onMouseLeave={() => setExpanded(false)}>
      <img className='profile-img' src={person.image} alt='' />
      <div className='name'>{person.name}</div>
      <p className='title'>Cost Segregation Expert</p>
      <div className='icons'>
        {person.linkedIn && (
          <a href={person.linkedIn} target='_blank' rel='noopener noreferrer'>
            <LinkedIn />
          </a>
        )}
        {person.facebook && (
          <a href={person.facebook} target='_blank' rel='noopener noreferrer'>
            <Facebook />
          </a>
        )}
        {person.bigPockets && (
          <a href={person.bigPockets} target='_blank' rel='noopener noreferrer'>
            <img src={bigpocketImg} alt='big pockets' />
          </a>
        )}
        {person.itunes && (
          <a href={person.itunes} target='_blank' rel='noopener noreferrer'>
            <img src={itunesImage} alt='itunes' />
          </a>
        )}
        {person.email && (
          <a
            href={`mailto: ${person.email}`}
            target='_blank'
            rel='noopener noreferrer'
            className='customSocialCircle'
          >
            <EmailIcon />
          </a>
        )}
        {person.youtube && (
          <a href={person.youtube} target='_blank' rel='noopener noreferrer'>
            <img src={YoutubeIcon} alt='youtube icon' />
          </a>
        )}
      </div>
      <div className='help'>How can i help?</div>
      <Collapser expanded={expanded}>
        <div className='hey'>{person.message}</div>
        <NavLinkButton to={subdomain === 'brianbigham' || subdomain === 'edenmarkowitz' ? '/custom-quote' : `/feasibility-analysis/${determinePath(subdomain)}`} target='_blank'>
          How can I get a<br />
          free feasibility analysis?
        </NavLinkButton>
        <NavLinkButton to='/faq'>
          Where can I learn more
          <br />
          about Cost-seg?
        </NavLinkButton>
        {person.calendly && (
          <Button target='_blank' href={person.calendly}>
            When can we talk?
          </Button>
        )}
      </Collapser>
      <div className='arrow-wrap' onMouseOver={() => setExpanded(true)}>
        <Arrow />
      </div>
    </DesktopCard>
  );
}
